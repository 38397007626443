/* eslint-disable max-lines-per-function */

import { actions, assign, spawn } from "xstate";
import { v4 as uuidv4 } from "uuid";
import { generateCreateDataMachine } from "@app/data/machine/generators/generateCreateDataMachine";
import { generateUpdateDataMachine } from "@app/data/machine/generators/generateUpdateDataMachine";
import { generateDeleteDataMachine } from "@app/data/machine/generators/generateDeleteDataMachine";
import {
    generateTransientObject,
    generateTransientObjectList
} from "@app/data/machine/actors/optimisticDataActorMachine/utils";
import { generateLoadDataMachine } from "@app/data/machine/generators/generateLoadDataMachine";
import { staticActions } from "@app/data/machine/actors/optimisticDataActorMachine/staticActions";
import { applyAdvancedFilters } from "@app/grid/advancedFilters/advancedFilters";
import { generateCreateInBatchDataMachine } from "../../generators/generateCreateInBatchDataMachine";
import { generateUpdateInBatchDataMachine } from "@app/data/machine/generators/generateUpdateInBatchMachine";

const { pure, send } = actions;

export const optimisticDataActorMachineActionsBuilder = ({
    updateMachineOptions,
    createMachineOptions,
    createInBatchMachineOptions,
    loadMachineOptions,
    deleteMachineOptions,
    transientConfig,
    updateInBatchMachineOptions
}) => {
    const spawnLoadDataActor = assign({
        loadDataActorList: (context, event) => {
            const loadDataMachineId = uuidv4();
            const { projectionAttributeList, preFilterEntityMap } = event;
            const loadDataMachine = generateLoadDataMachine({ context: { projectionAttributeList, loadDataMachineId, ...event, entity: context.entity, preFilterEntityMap }, loadMachineOptions });
            return [
                ...context.loadDataActorList,
                {
                    projectionAttributeList,
                    loadDataMachineId,
                    ref: spawn(loadDataMachine, loadDataMachineId),
                },
            ];
        },
    });

    const spawnCreateDataActor = assign({
        createDataActorList: (context, event) => {
            const { transientObject, transientChangeObject } = event;
            const createDataMachine = generateCreateDataMachine({ context: { object: transientObject, transientChangeObject, gidAttributesList: event.gidAttributesList, keyProperty: context.keyProperty }, createMachineOptions });
            return [
                ...context.createDataActorList,
                {
                    object: transientObject,
                    ref: spawn(createDataMachine),
                },
            ];
        },
    });

    const spawnCreateInBatchDataActor = assign({
        createInBatchDataActorList: (context, event) => {
            const { transientObjectList } = event;
            const createInBatchDataMachine = generateCreateInBatchDataMachine({ context: { objectList: transientObjectList, gidAttributesList: event.gidAttributesList }, createInBatchMachineOptions });
            return [
                ...context.createInBatchDataActorList,
                {
                    objectList: transientObjectList,
                    ref: spawn(createInBatchDataMachine),
                },
            ];
        },
    });

    const spawnUpdateDataActor = assign({
        updateDataActorList: (context, event) => {
            const { keyProperty } = context;
            const { transientObject } = event;
            const updateDataMachine = generateUpdateDataMachine({ context: { object: { ...transientObject }, fieldBeingUpdated: { ...(event?.fieldBeingUpdated ?? {}) } }, updateMachineOptions });
            const updateDataActorListWithoutCurrentTransientObject = context.updateDataActorList.filter((updateDataActorObj) => updateDataActorObj.object[keyProperty] !== transientObject[keyProperty]);
            return [
                ...updateDataActorListWithoutCurrentTransientObject,
                {
                    object: { ...transientObject },
                    ref: spawn(updateDataMachine),
                },
            ];
        },
    });

    const spawnUpdateInBatchDataActor = assign({
        updateInBatchDataActorList: (context, event) => {
            const { keyProperty } = context;
            const { transientObjectList } = event;
            const updateInBatchDataMachine = generateUpdateInBatchDataMachine({
                context: {
                    objectList: { ...transientObjectList },
                    fieldBeingUpdated: { ...(event?.fieldBeingUpdated ?? {}) }
                }, updateInBatchMachineOptions
            });
            const updateDataActorListWithoutCurrentTransientObject = context.updateInBatchDataActorList.filter((updateDataActorObj) => updateDataActorObj.object[keyProperty] !== transientObjectList[keyProperty]);
            return [
                ...updateDataActorListWithoutCurrentTransientObject,
                {
                    objectList: { ...transientObjectList },
                    ref: spawn(updateInBatchDataMachine),
                },
            ];
        }
    });

    const spawnUpdateGroupDataActor = assign({
        updateDataActorList: (context, event) => {
            const { keyProperty } = context;
            const { transientObject } = event;
            const updateDataMachine = generateUpdateDataMachine({ context: { object: { ...event.object }, fieldBeingUpdated: { ...(event?.fieldBeingUpdated ?? {}) } }, updateMachineOptions });
            const updateDataActorListWithoutCurrentTransientObject = context.updateDataActorList.filter((updateDataActorObj) => updateDataActorObj?.object?.[keyProperty] !== transientObject?.[keyProperty]) ?? [];
            return [
                ...updateDataActorListWithoutCurrentTransientObject,
                {
                    object: { ...transientObject },
                    ref: spawn(updateDataMachine),
                },
            ];
        },
    });

    const deleteObjectFromData = assign({
        evaluatedData: (context, event) => {
            const { keyProperty } = context;
            spawn(generateDeleteDataMachine({ context: { object: event.object }, deleteMachineOptions }));
            return context.evaluatedData.filter((dataObj) => dataObj[keyProperty] !== event.object[keyProperty]);
        },
        transientData: (context, event) => {
            const { keyProperty } = context;
            return context.transientData.filter((transientDataObj) => transientDataObj.object[keyProperty] !== event.object[keyProperty]);
        },
    });

    const generateTransientObjectToCreate = pure((context, event) => {
        const { keyProperty } = context;
        const { createOptions = {}, object } = event;
        const { fullObjectChangedData = false } = createOptions;
        const transientObject = generateTransientObject({ event, transientConfig, transientValueGetter: transientConfig.transientValueOnCreate });
        const transientChangeObject = {
            ...(fullObjectChangedData ? object : {}),
            [transientConfig.transientProperty]: transientObject[transientConfig.transientProperty],
            [keyProperty]: transientObject[keyProperty]
        };
        return send({ ...event, type: "GENERATED_TRANSIENT_OBJECT_TO_CREATE", transientObject, transientChangeObject });
    });

    const generateTransientObjectToCreateInBatch = pure((context, event) => {
        const { keyProperty } = context;
        const transientObjectList = generateTransientObjectList({ event, transientConfig, transientValueGetter: transientConfig.transientValueOnCreate });
        const transientChangeObjectList = transientObjectList.map((transientObject) => {
            return { [transientConfig.transientProperty]: transientObject[transientConfig.transientProperty], [keyProperty]: transientObject[keyProperty] };
        });
        return send({ ...event, type: "GENERATED_TRANSIENT_OBJECT_LIST_TO_CREATE", transientObjectList, transientChangeObjectList });
    });

    const generateTransientObjectToUpdate = pure((context, event) => {
        const { keyProperty, savedQuery } = context;
        const { createOptions = {}, fieldBeingUpdated, object } = event;
        const { fullObjectChangedData = false } = createOptions;
        const transientObject = generateTransientObject({ context, event, transientConfig, transientValueGetter: transientConfig.transientValueOnUpdate });
        const transientChangeObject = {
            ...(fullObjectChangedData ? object : {}),
            ...fieldBeingUpdated,
            [transientConfig.transientProperty]: transientObject[transientConfig.transientProperty],
            [keyProperty]: transientObject[keyProperty]
        };
        let onlyForPolling = false;
        if (savedQuery && applyAdvancedFilters({ logic: savedQuery.savedQueryObject, rows: [transientObject] }).length === 0) {
            onlyForPolling = true;
        }
        return send({ ...event, type: "GENERATED_TRANSIENT_OBJECT_TO_UPDATE", transientObject, transientChangeObject, onlyForPolling });
    });

    const generateTransientObjectToUpdateInBatch = pure((context, event) => {
        const { keyProperty, savedQuery } = context;
        const { fieldBeingUpdated } = event;
        const transientObjectList = generateTransientObjectList({
            context,
            event,
            transientConfig,
            transientValueGetter: transientConfig.transientValueOnUpdate
        });
        const transientChangeObjectList = transientObjectList.map((transientObject) => {
            return {
                ...fieldBeingUpdated,
                [transientConfig.transientProperty]: transientObject[transientConfig.transientProperty],
                [keyProperty]: transientObject[keyProperty]
            };
        });
        let onlyForPolling;
        if (savedQuery && applyAdvancedFilters({
            logic: savedQuery.savedQueryObject,
            rows: transientObjectList
        }).length === 0) {
            onlyForPolling = true;
        }
        return send({
            ...event,
            type: "GENERATED_TRANSIENT_OBJECT_LIST_TO_UPDATE",
            transientObjectList,
            transientChangeObjectList,
            onlyForPolling
        });
    });

    return {
        spawnLoadDataActor,
        spawnCreateDataActor,
        spawnCreateInBatchDataActor,
        spawnUpdateDataActor,
        spawnUpdateInBatchDataActor,
        deleteObjectFromData,
        generateTransientObjectToUpdate,
        generateTransientObjectToUpdateInBatch,
        generateTransientObjectToCreate,
        generateTransientObjectToCreateInBatch,
        spawnUpdateGroupDataActor,
        ...staticActions,
    };
};
